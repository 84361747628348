import React from 'react'
import PropTypes from 'prop-types'
import { gql } from '@apollo/client'
import {
  FaTwitter,
  FaFacebook,
  FaLinkedinIn,
  FaWhatsapp,
  FaFacebookMessenger,
} from 'react-icons/fa'

import { PageIntro, NewsletterForm, FormSection } from '#sections'
import {
  Layout,
  SectionTitle,
  ContentSection,
  LinkLine,
  withPreview,
} from '#components'
import { PREVIEW_POST_QUERY } from './post.data'

function Post({ preview, pageContext }) {
  const { title, content } = preview
    ? preview.post.revisions.nodes[0]
    : pageContext

  const { date, author, categories, featuredImage } = preview
    ? preview.post
    : pageContext

  const formattedDate = new Intl.DateTimeFormat(`en-UK`, {
    dateStyle: `long`,
  }).format(new Date(date))

  return (
    <Layout title={title}>
      <PageIntro preview={preview} backgroundImage={featuredImage} />
      <div className="bg-white relative pb-55 md:pb-65">
        <div className="container pt-45 md:pt-55">
          <SectionTitle
            title="Blog"
            mainLink="/our-blog/"
            links={categories.nodes}
          />

          <div className="mt-30 md:mt-55 px-25 md:px-30 pb-15 md:pb-20 pt-20 md:pt-25 bg-red text-white">
            <h1
              className="text-20 sm:text-27 md:text-32 font-normal leading-tight max-w-750"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p className="text-17 mt-5">{formattedDate}</p>
          </div>

          <div className="md:px-30 py-40 md:py-55">
            <ContentSection content={content} />

            <div className="flex flex-wrap justify-between items-start pt-40">
              <div className="w-full md:w-1/2">
                <h3 className="font-bold leading-tight text-20 md:text-27 lg:text-32">
                  Written by{` `}
                  <LinkLine to={author.node.uri}>{author.node.name}</LinkLine>
                </h3>
                <p className="text-17 md:text-19 mt-10">{formattedDate}</p>
              </div>

              <div className="w-full md:w-1/2 text-left pt-30 md:pt-0 md:text-right">
                <p className="text-17 md:text-19 font-bold">Share this post</p>
                <div className="flex items-center md:justify-end pt-10">
                  <a
                    href="https://facebook.com"
                    target="_blank"
                    rel="noreferrer"
                    className="text-50 flex w-45 h-45 items-center justify-center transition-all duration-300 hover:text-facebook"
                  >
                    <FaFacebook />
                  </a>
                  <a
                    href="https://facebook.com"
                    target="_blank"
                    rel="noreferrer"
                    className="text-50 flex w-45 h-45 items-center justify-center ml-15 transition-all duration-300 hover:text-messenger"
                  >
                    <FaFacebookMessenger />
                  </a>
                  <a
                    href="https://whatsapp.com"
                    target="_blank"
                    rel="noreferrer"
                    className="text-50 flex w-45 h-45 items-center justify-center ml-15 transition-all duration-300 hover:text-whatsapp"
                  >
                    <FaWhatsapp />
                  </a>
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noreferrer"
                    className="text-24 flex w-45 h-45 items-center justify-center ml-15 rounded-full text-white bg-black transition-all duration-300 hover:bg-twitter"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noreferrer"
                    className="text-24 flex w-45 h-45 items-center justify-center ml-15 rounded-full text-white bg-black transition-all duration-300 hover:bg-linkedin"
                  >
                    <FaLinkedinIn />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsletterForm />
      <FormSection />
    </Layout>
  )
}

Post.propTypes = {
  preview: PropTypes.any,
  pageContext: PropTypes.object,
}

export default withPreview({ preview: gql(PREVIEW_POST_QUERY) })(Post)
