module.exports.POST_QUERY = /* GraphQL */ `
  fragment PostContent on WpPost {
    uri
    title
    content
    date
    featuredImage {
      node {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 100) {
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
            }
          }
        }
      }
    }
    author {
      node {
        name
        uri
      }
    }
    categories {
      nodes {
        name
        uri
      }
    }
  }

  query ALL_POSTS {
    wp {
      postOptions {
        postOptions {
          archivePageFeaturedImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  presentationWidth
                }
              }
            }
          }
        }
      }
    }
    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        ...PostContent
      }
    }
    allWpCategory {
      nodes {
        name
        uri
        posts {
          nodes {
            ...PostContent
          }
        }
      }
    }
    allWpUser {
      nodes {
        name
        uri
        posts {
          nodes {
            ...PostContent
          }
        }
      }
    }
  }
`;

module.exports.PREVIEW_POST_QUERY = /* GraphQL */ `
  query PREVIEW_POST($id: ID!) {
    post(id: $id, idType: DATABASE_ID) {
      date
      author {
        node {
          name
          uri
        }
      }
      categories {
        nodes {
          name
          uri
        }
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      revisions {
        nodes {
          title
          content
          date
        }
      }
    }
  }
`
